export const convertStringToArray = (str) =>  {
    return str ? str.split(',').map(Number) : [];
};


export const convertStringArrayToNumberArray = (arr) => {
    if (arr.length !== 1 || typeof arr[0] !== 'string') {
        return [];
    }

    return arr[0].split(',').map(Number);
};
